<template>
  <div class="mt-4 mb-3 d-flex flex-column">
    <BaseModal :show.sync="showAddPlayer" class="modal-search" id="addPlayerModal" :centered="false" :show-close="true">
      <div class="col-6 form-group row">
        <label class="control-label form-text col-6">Search player</label>
        <input
          v-model="playerSearch"
          type="text"
          @keyup.enter="searchUsers()"
          aria-describedby="addon-right addon-left"
          placeholder="Search a user by nick"
          class="col-6 form-control d-inline mb-3 border white"
        />
      </div>

      <div class="col-12 my-3">
        <base-table :data="playersFoundTable.data" :columns="playersFoundTable.columns" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td @click="copyToClipboard(row.id)" class="pointer px-1">
              {{ row.id }}
            </td>
            <td>
              {{ row.nick }}
            </td>
            <td>
              <font-awesome-icon icon="fa-solid fa-plus" class="mx-2 pointer" @click="addPlayer(row)" />
            </td>
          </template>
        </base-table>

        <div v-if="!playersFoundTable.data?.length" class="col-12 my-3 text-center">
          There are not players with the nick {{ playerSearch }}.
        </div>
      </div>
    </BaseModal>

    <div v-if="hasStats" class="col-12 my-3 row justify-content-center">
      <div class="col-4">
        <div class="col-12 mr-3 p-3 card">
          <h3 class="row justify-content-center">Money earned</h3>
          <h1 class="row justify-content-center">${{ moneyEarned.toFixed(2) }}</h1>
        </div>
      </div>
      <div class="col-4">
        <div class="col-12 mx-3 p-3 card">
          <h3 class="row justify-content-center">Pools played</h3>
          <h1 class="row justify-content-center">{{ poolsPlayed }}</h1>
        </div>
      </div>
      <div class="col-4">
        <div class="col-12 ml-3 p-3 card">
          <h3 class="row justify-content-center">Pools won</h3>
          <h1 class="row justify-content-center">{{ poolsWon }}</h1>
        </div>
      </div>
    </div>

    <span v-if="loading">
      <LoadingComponent />
    </span>

    <div v-if="!loading">
      <div class="row col-12">
        <h3 class="col-9">
          {{ playersTable.title }}
        </h3>
        <span class="col-3 flex justify-content-end">
          <base-button simple type="primary" style="cursor: pointer" @click="showAddPlayer = true">
            Add player
          </base-button>
        </span>
      </div>

      <div class="table-responsive">
        <base-table :data="playersTable.data" :columns="playersTable.columns" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td @click="copyToClipboard(row.id)" class="pointer px-1">
              {{ row.id }}
            </td>
            <td>{{ row.nick }}</td>
            <td>
              <span v-if="row.role === 'captain'"><img src="@/assets/svg/crown.svg" /></span>
              <span v-if="row.role !== 'captain'" @click="memberToCaptain(row)" class="pointer"
                ><img class="member" src="@/assets/svg/profile-icon.svg"
              /></span>
            </td>
            <td>{{ row.status }}</td>
            <td class="td-actions text-right">
              <font-awesome-icon icon="fa-solid fa-trash" class="mx-2 pointer" @click="removePlayer(row)" />
            </td>
          </template>
        </base-table>
      </div>
    </div>

    <div v-if="hasAccounts" class="mt-3">
      <div class="row col-12">
        <h3 class="col-12">{{ accountsTable.title }}</h3>
      </div>

      <div class="table-responsive">
        <base-table :data="accountsTable.data" :columns="accountsTable.columns" thead-classes="text-primary">
          <template slot-scope="{ row }">
            <td>
              {{ row.accountId }}
            </td>
            <td>{{ row.nick }}</td>
            <td>{{ row.name }}</td>
            <td @click="copyToClipboard(row.username)" class="pointer px-1">{{ row.username }}</td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable, BaseModal } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadingComponent from '@/components/LoaderSpinner';

const playersFoundColumns = ['Id', 'Nick', 'Actions'];
const playersTableColumns = ['Id', 'Nick', 'Role', 'Status', 'Actions'];
const accountsTableColumns = ['Account Id', 'Player', 'Platform', 'Nick'];

export default {
  name: '',
  components: {
    BaseTable,
    BaseModal,
    FontAwesomeIcon,
    LoadingComponent,
  },
  mixins: [],
  props: {
    selectedTeam: {
      type: Object,
      default: () => {},
    },
    usersAccounts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      teamStats: {},
      playerSearch: '',
      playersFoundTable: {
        title: 'Players found',
        columns: [...playersFoundColumns],
        data: [],
      },
      playersTable: {
        title: 'Players',
        columns: [...playersTableColumns],
        data: [],
      },
      accountsTable: {
        title: 'Accounts',
        columns: [...accountsTableColumns],
        data: [],
      },
      showAddPlayer: false,
    };
  },
  computed: {
    hasStats() {
      return Object.keys(this.teamStats).length > 0;
    },
    hasAccounts() {
      return this.accountsTable.data.length > 0;
    },
    moneyEarned() {
      const moneyEarned = this.teamStats.find(stat => stat.name === 'MONEY_EARNED');

      return moneyEarned ? moneyEarned.value : 0;
    },
    poolsPlayed() {
      const poolsPlayed = this.teamStats.find(stat => stat.name === 'POOLS_PLAYED');

      return poolsPlayed ? poolsPlayed.value : 0;
    },
    poolsWon() {
      const poolsWon = this.teamStats.find(stat => stat.name === 'POOLS_WON');

      return poolsWon ? poolsWon.value : 0;
    },
  },
  watch: {
    selectedTeam(newValue) {
      this.parseTeam(newValue);
    },
  },
  methods: {
    parseTeam(team) {
      const { players, stats } = team;

      const playersData = players.map(player => {
        const { nick, role, status, userId: id } = player;

        return {
          id,
          nick,
          role,
          status,
        };
      });

      playersData.sort((a, b) => a.nick.localeCompare(b.nick));

      this.playersTable.data = playersData;
      this.teamStats = stats;
    },
    parseUsersAccounts(usersAccounts) {
      if (!usersAccounts.length) {
        return;
      }

      for (const user of usersAccounts) {
        const { userId, accounts } = user;
        let nick = '';

        const playerIdx = this.playersTable.data.findIndex(player => player.id === userId);

        if (playerIdx >= 0) {
          nick = this.playersTable.data[playerIdx].nick;
        }

        const userAccountsData = accounts.map(account => {
          const { name, username, reference: accountId } = account;

          return {
            accountId,
            name,
            nick,
            userId,
            username,
          };
        });

        this.accountsTable.data = [...this.accountsTable.data, ...userAccountsData];
      }

      this.accountsTable.data.sort((a, b) => {
        const nickComparison = a.nick.localeCompare(b.nick);

        return nickComparison !== 0 ? nickComparison : a.name.localeCompare(b.name);
      });
    },
    async memberToCaptain(newCaptain) {
      const { id: userId, nick } = newCaptain;

      try {
        this.loading = true;

        const params = {
          teamId: this.selectedTeam.id,
          userId,
        };

        await this.$store.dispatch('teams/updateCaptain', params);

        this.$notify({ type: 'success', message: `The new captain is: ${nick}` });
        this.$emit('updateTeam');

        this.loading = false;
      } catch (error) {
        console.log(error);

        await this.$notify({
          type: 'danger',
          message: 'Error updating captain! The given data was invalid.',
        });
      }
    },
    async searchUsers() {
      // this.loading = true;

      const data = { searchQuery: this.playerSearch };
      const usersResponse = await this.$store.dispatch('users/searchUsers', data);
      const { error: usersError, message: usersMessage } = usersResponse;

      if (usersError) {
        await this.$notify({
          type: 'danger',
          message: `Error getting users: ${usersMessage}`,
        });

        // this.loading = false;
        return;
      }

      this.playersFoundTable.data = this.$store.getters['users/users'];
      // this.loading = false;
    },
    async addPlayer(player) {
      const teamId = this.$route.params.teamId;
      const { id: userId, nick } = player;

      const data = { teamId, userId };
      const addPlayerResponse = await this.$store.dispatch('teams/addPlayer', data);

      const { error: addPlayerError, message: addPlayerMessage } = addPlayerResponse;

      if (addPlayerError) {
        await this.$notify({
          type: 'danger',
          message: `Error adding ${nick} to the team: ${addPlayerMessage}.`,
        });

        return;
      }

      await this.$notify({ type: 'success', message: `${nick} added to the team!` });

      this.$emit('updateTeam');
      return;
    },
    async removePlayer(player) {
      const teamId = this.$route.params.teamId;
      const { id: userId, nick } = player;

      const data = { teamId, userId };
      const addPlayerResponse = await this.$store.dispatch('teams/removePlayer', data);

      const { error: addPlayerError, message: addPlayerMessage } = addPlayerResponse;

      if (addPlayerError) {
        await this.$notify({
          type: 'danger',
          message: `Error removing ${nick} to the team: ${addPlayerMessage}.`,
        });

        return;
      }

      await this.$notify({ type: 'success', message: `${nick} removed from the team!` });

      this.$emit('updateTeam');
      return;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);

      this.$notify({ type: 'success', message: `${text} copied to clipboard!` });
    },
  },
  mounted() {
    this.loading = true;

    this.parseTeam(this.selectedTeam);
    this.parseUsersAccounts(this.usersAccounts);

    this.loading = false;
  },
};
</script>

<style>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}
.form-text {
  color: white;
  font-size: 14px !important;
}

.color {
  color: #222a42;
}

.white {
  color: white !important;
}

.member {
  height: 15px;
  width: 15px;
}

.btn {
  margin: 0px !important;
  padding: 0px 25px;
}
</style>
