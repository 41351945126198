import axios from "axios";

import { storeHelper } from "../helpers";

const url = process.env.VUE_APP_BLAST_API_BASE_URL;

const state = {
  team: {},
  teams: [],
};

const mutations = {
  SET_TEAM: (state, team) => {
    state.team = team;
  },
  SET_TEAMS: (state, teams) => {
    state.teams = teams;
  },
}

const getters = {
  teams: state => state.teams,
};

const actions = {
  async getTeams({ commit }, data) {
    try {
    	const { limit = 10, page = 1 } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const team = await axios.get(`${url}/admin/team?limit=${limit}&page=${page}`, config);
      const { data: teamsData } = team;
  
      commit('SET_TEAMS', teamsData);
  
      return teamsData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async getTeam({ commit }, data) {
    try {
      const { teamId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const team = await axios.get(`${url}/admin/team/${teamId}`, config);
      const { data: teamData } = team;

      commit('SET_TEAM', teamData);
  
      return teamData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
	async getTeamUsersAccounts({ commit }, data) {
    try {
      const { teamId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
  
      const teamUsersAccounts = await axios.get(`${url}/admin/team/accounts/${teamId}`, config);
      const { data: teamUsersAccountsData } = teamUsersAccounts;

      return teamUsersAccountsData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
	async searchTeams({ commit }, data) {
    try {
      const { searchQuery } = data;
      const queryParams = `?&name=${searchQuery}`;
      const config = {
        headers: { token: localStorage.getItem('auth_access_token') },
      };

      const teams = await axios.get(`${url}/admin/team/search${queryParams}`, config);
      const { data: teamsData } = teams;

      commit('SET_TEAMS', teamsData);

      return true;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
	async updateCaptain({ commit }, data) {
    try {
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const updateTeamCaptiain = await axios.put(`${url}/admin/team/captain`, data, config);
      const { data: teamParams } = updateTeamCaptiain;
  
      return teamParams;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async addPlayer({ commit }, data) {
    try {
      const { teamId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") }
      };
      
      const addPlayer = await axios.post(`${url}/admin/team/${teamId}/addPlayer`, data, config);
      const { data: addPlayerData } = addPlayer;
  
      return addPlayerData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
  async removePlayer({ commit }, data) {
    try {
      const { teamId } = data;
      const config = {
        headers: { token: localStorage.getItem("auth_access_token") },
        data
      };
      
      const removePlayer = await axios.delete(`${url}/admin/team/user/${teamId}`, config);
      const { data: removePlayerData } = removePlayer;
  
      return removePlayerData;
    } catch (error) {
      const status = error.response?.status;
      const errorResponse = storeHelper.errorRequest(status, error);

      return errorResponse;
    }
  },
};

const teams = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};

export default teams;
